<template>
    <div class="p-details-wrapper" :class="{'--gray-bg' : !this.item}">
        <div class="p-details" :class="{'loading': loading.data, 'container pt-4 pb-4' : !this.item}">
            <div class="row" v-if="offer">
                <div class="col-md-12" v-if="offer.status === -1">
                    <div class="alert alert-danger">
                        <div class="alert-title">Blocked/Declined</div>
                        <div class="alert-body">
                            <div class="mb-1"><b>Reason: </b>{{ offer.reject_reason }}</div>
                            <div><b>Details: </b>{{ offer.reject_reason_details }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="p-details__info">
                                <UserAvatar class="mb-3" size="large" :user="offer.client"/>
                                <div class="p-details__info__title">{{ getClientName(offer.client) }}</div>
                                <div class="p-details__info__desc">
                                    {{ offer.client.description }}
                                </div>
                                <div class="badge badge-secondary-light badge-lg mb-3" v-if="offer.client.attributes.availability">Availability: {{ offer.client.attributes.availability?.value }}</div>
                                <div class="p-details__info__stats" v-if="offer.client">
                                    <span v-if="offer.client.address"><LocationIcon/> {{ [offer.client.address.city_name, offer.client.address.country_name].join(', ') }}</span>
                                    <span><StarIcon/> {{ offer.client.avgReview ? offer.client.avgReview : '-' }}/5 <template v-if="offer.client.countReview">({{ offer.client.countReview }})</template></span>
                                </div>
                                <div class="row" style="width: 100%;" v-if="offer.status == 0">
                                    <template v-if="offer.user_id === user.id">
                                        <div class="col-12">
                                            <Btn class="btn-outline-danger btn-block mb-2" @click="$emit('onCancel', offer)">Cancel</Btn>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col-6">
                                            <Btn class="btn-outline-light btn-block mb-2" @click="$emit('onOpenRejectModal', offer)">Decline</Btn>
                                        </div>
                                        <div class="col-6">
                                            <Btn class="btn-secondary btn-block mb-2" @click="$emit('onAcceptOffer', offer)">Accept</Btn>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2" v-if="offer.team">
                        <div class="card-header">
                            <div class="card-title">Working With</div>
                        </div>
                        <div class="card-body">
                            <UserAvatar :display-name="true" :user="offer"/>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="p-details__specs">

                                <div class="p-details__specs__label">Number Or Reviews</div>
                                <div class="p-details__specs__content">{{ offer.client.countReview ?? '-' }}</div>

                                <div class="p-details__specs__label">Member Since</div>
                                <div class="p-details__specs__content">{{ offer.client.member_since | moment('MMMM DD, YYYY') }}</div>

                                <div class="p-details__specs__label">Response Time</div>
                                <div class="p-details__specs__content">{{ offer.client.attributes.response_time?.value ?? '-' }}</div>

                                <div class="p-details__specs__label">Last Online</div>
                                <div class="p-details__specs__content">{{ offer.client.online_at | onlineAtFormat }}</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="card" style="padding-top: 18px;">
                        <div class="p-details__section" id="description">
                            <div class="p-details__section-title">
                                Additional details
                            </div>
                            <div class="p-details__activity"><b>Revisions requested:</b> {{ offer.revisions ? offer.revisions : '-' }}</div>
                            <template v-if="offer.payment_method === 'hourly'">
                                <div class="p-details__activity"><b>Hourly Rate:</b> {{ offer.price | toPrice }} / h</div>
                                <div class="p-details__activity"><b>Duration of the Project:</b> {{ offer.duration_min }} - {{ offer.duration_max }} Days</div>
                                <div class="p-details__activity"><b>Number of Hours Required:</b> {{ offer.hours_min }} - {{ offer.hours_max }} Hours / {{ offer.hours_type | beautify }}</div>
                            </template>
                            <template v-if="offer.payment_method === 'fixed'">
                                <div class="p-details__activity"><b>Fixed Price:</b> {{ offer.price | toPrice }}</div>
                                <div class="p-details__activity"><b>Delivery Time:</b> {{ offer.duration_min }} - {{ offer.duration_max }} Days</div>
                            </template>
                        </div>
                        <div class="p-details__section" id="skills">
                            <div class="p-details__section-title">
                                Description of the offer.
                            </div>
                            <p>
                                {{ offer.description }}
                            </p>
                        </div>
                        <div class="p-details__section" id="documents" v-if="offer.documents?.length">
                            <div class="p-details__section-title">
                               Attached Documents
                            </div>
                            <div class="p-details__docs-wrapper">
                                <DocumentCard :key="document.id" :document="document" v-for="document in offer.documents" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserAvatar from "@/components/UserAvatar.vue";
import Btn from "@/components/Btn.vue";
import ColorCodeAttribute from "@/components/ColorCodeAttribute.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import DocumentCard from "@/components/DocumentCard.vue";
import {getClientName} from "../../utils/helpers";
import {mapState} from "vuex";

export default {
    name: 'OfferDetails',
    components: {
        DocumentCard,
        Tab, Tabs, ColorCodeAttribute, Btn, UserAvatar,
    },
    props: ['item', 'preview'],
    data() {
        return {
            loaded: false,
            loading: {
                data: false,
                reviews: false,
                load_mode: false,
            },
            offer: null,
            reviews: [],
            total_reviews: 0,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch();
        }
    },
    fetch() {
        if (this.preview && this.item) {
            this.offer = JSON.parse(JSON.stringify(this.item));
            return;
        }
        this.getData();
    },
    watch: {
        currentSection(value) {
            if (this.swiper && value) {
                const sections = document.querySelectorAll('.p-details__section');
                const section = document.querySelector('.p-details__section#' + value);
                const index = Array.prototype.indexOf.call(sections, section);
                this.swiper.slideTo(index + 1);
            }
        },
    },
    methods: {
        getClientName,
        onSwiper(swiper) {
            this.swiper = swiper;
            this.currentSection = document.querySelectorAll('.p-details__section')[0]?.id
        },
        scrollHandler() {

            const elements = document.querySelectorAll('.p-details__section')
            let currentSection = elements[0];
            elements.forEach((element, index) => {
                const position = element.getBoundingClientRect().y - 40;
                if (position < 0) {
                    currentSection = element;
                }
            })
            this.currentSection = currentSection ? currentSection.id : null
            console.log(this.currentSection)
        },
        getData () {
            this.loading.data = true;
            return this.$axios.post('/account/projects/offers/details', { id: this.item.id })
                .then((res) => {
                    this.offer = res.offer
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
    }
}
</script>
