import Vue from 'vue'
import VueSocialAuth from 'vue-social-auth'

export default (obj, inject) => {
  // aviable only in client side
  if (!process.client) {
    return
  }

  //initialize plugin with options
  const pluginOptions = [{"property":"$socialAuth","providers":{"google":{"clientId":"720536592512-c26pr0irr546mapf5fsfs1veou5cimbu.apps.googleusercontent.com","redirectUri":"https:\u002F\u002Fbolg.co\u002Fsocial-login\u002Fgoogle\u002Fcallback"},"linkedin":{"clientId":"77h64l86yoxtas","redirectUri":"https:\u002F\u002Fbolg.co\u002Fsocial-login\u002Flinkedin\u002Fcallback"},"paypal":{"name":"paypal","url":"\u002Fauth\u002Fpaypal","authorizationEndpoint":"https:\u002F\u002Fwww.paypal.com\u002Fconnect\u002F","redirectUri":"https:\u002F\u002Fbolg.co\u002Fsocial-login\u002Fpaypal\u002Fcallback","requiredUrlParams":["flowEntry","scope"],"scope":["openid","profile","email","address"],"scopeDelimiter":" ","display":"popup","oauthType":"2.0","popupOptions":{"width":580,"height":400},"clientId":"ATIQxqD-eMx9f9lgZQe1m2Y0bK-7_AYmZbvd-7BcgeLa7ehVZPYNq1F7gSKJPxcHo9qqrJyMBA5N2IBb","flowEntry":"static"}}}][0] || {}
  Vue.use(VueSocialAuth, pluginOptions)
}
