<template>
    <div>
        <div class="container">
            <div class="l-page-content">
                <div class="l-page-title">
                    How It <span class="--underlined">Works</span>
                </div>
                <div class="how-it-works__path">
                    <div class="how-it-works__path-row">
                        <div class="how-it-works__path-col --small"><Works1 class="img-fluid"/></div>
                        <div class="how-it-works__path-col --large">
                            <div class="how-it-works__step">1</div>
                            <div class="how-it-works__step-title">Search</div>
                            <div class="how-it-works__step-desc">
                                <div><b>User Action:</b> Clients search for biotech freelancers or specific biotech services on
                                    the platform.</div>
                                <div><b>Platform Function:</b> Filters and search tools help clients find suitable biotech
                                    freelancers or services based on skills, ratings, availability, and other criteria.</div>
                            </div>
                        </div>
                    </div>

                    <div class="how-it-works__path-row">
                        <div class="how-it-works__path-col --large --right">
                            <div class="how-it-works__step">2</div>
                            <div class="how-it-works__step-title">Decide</div>
                            <div class="how-it-works__step-desc">
                                <div><b>User Action:</b> Clients review profiles, portfolios, and feedback to select the best
                                    biotech freelancer for their project.</div>
                                <div><b>Platform Function:</b> Provides detailed profiles, reviews, and ratings for biotech
                                    freelancers to help clients make informed decisions.</div>
                            </div>
                        </div>
                        <div class="how-it-works__path-col --small"><Works2 class="img-fluid"/></div>
                    </div>

                    <div class="how-it-works__path-row">
                        <div class="how-it-works__path-col --small"><Works3 class="img-fluid"/></div>
                        <div class="how-it-works__path-col --large">
                            <div class="how-it-works__step">3</div>
                            <div class="how-it-works__step-title">Purchase</div>
                            <div class="how-it-works__step-desc">
                                <div><b>User Action:</b> Clients agree on project terms with the biotech freelancer and make
                                    an upfront payment for the agreed-upon hours or fixed price.</div>
                                <div><b>Platform Function:</b> Securely holds the payment in escrow until the work is
                                    completed and approved.</div>
                            </div>
                        </div>
                    </div>

                    <div class="how-it-works__path-row">
                        <div class="how-it-works__path-col --large --right">
                            <div class="how-it-works__step">4</div>
                            <div class="how-it-works__step-title">Execution</div>
                            <div class="how-it-works__step-desc">
                                <div>
                                    <b>User Action:</b> Biotech freelancers complete the work according to the agreed-
                                    upon terms and submit it for review.
                                </div>
                                <div>
                                    <b>Platform Function:</b> Allows biotech freelancers to log hours worked, update
                                    project status, and communicate with clients during the project.
                                </div>
                            </div>
                        </div>
                        <div class="how-it-works__path-col --small"><Works4 class="img-fluid"/></div>
                    </div>

                    <div class="how-it-works__path-row">
                        <div class="how-it-works__path-col --small"><Works5 class="img-fluid"/></div>
                        <div class="how-it-works__path-col --large">
                            <div class="how-it-works__step">5</div>
                            <div class="how-it-works__step-title">Approval</div>
                            <div class="how-it-works__step-desc">
                                <div><b>User Action:</b> Clients review the submitted work within a stipulated time frame
                                    (e.g., 5 days) and either approve it or request revisions.</div>
                                <div><b>Platform Function:</b> Releases the payment to the biotech freelancer after the
                                    client’s approval or a holding period if no disputes are raised.</div>
                            </div>
                        </div>
                    </div>

                    <div class="how-it-works__finish">
                        <PatternGreen class="how-it-works__finish-pattern"/>
                        The Expert will be paid once you approve that the work is complete within one week of payment.
                    </div>
                </div>

                <Newsletter/>
            </div>
        </div>
    </div>
</template>

<script>


import { groupBy } from 'lodash'
import { metaMixin } from '~/mixins/metaMixin'
import Bugsnag from "@bugsnag/js";

import Works1 from "~/assets/img/works1.svg?inline";
import Works2 from "~/assets/img/works2.svg?inline";
import Works3 from "~/assets/img/works3.svg?inline";
import Works4 from "~/assets/img/works4.svg?inline";
import Works5 from "~/assets/img/works5.svg?inline";
import PatternGreen from "~/assets/assets/pattern-green.svg?inline";

import Newsletter from "../partials/Newsletter.vue";

export default {
    name: 'HowItWorks',
    components: {Newsletter, Works1, Works2, Works3, Works4, Works5, PatternGreen},
    mixins: [metaMixin],
    data () {
        return {
            loading: {
                data: false,
            },
            team: [],
            brands: [
                {name: 'Boltshift', img: '/images/partners/boltshift.png'},
                {name: 'FeatherDev', img: '/images/partners/featherDev.png'},
                {name: 'GlobalBank', img: '/images/partners/globalBank.png'},
                {name: 'Lightbox', img: '/images/partners/lightbox.png'},
                {name: 'Nietzhsche', img: '/images/partners/nietzhsche.png'},
                {name: 'Spherule', img: '/images/partners/spherule.png'},
            ]
        }
    },
}
</script>
