import Echo from 'laravel-echo'
import Vue from 'vue';

window.Pusher = require('pusher-js')

function getHeaders ({ app }) {
  const headers = {}

 if(typeof Vue.$auth !== 'undefined'){
    let token = Vue.$auth.getToken();
    let profileId = Vue.$auth.getProfileId();
    headers['Authorization'] = `Bearer ${token}`
    // headers['Accept'] = 'application/json'
    // headers['Content-Type'] = 'application/json'
    headers['Profile-Id'] = profileId
  }

  return headers
}

export default (ctx, inject) => {
  const options = {"broadcaster":"pusher","plugins":null,"authModule":false,"connectOnLogin":false,"disconnectOnLogout":true,"authEndpoint":"https:\u002F\u002Fapi.bolg.co\u002Fbroadcasting\u002Fauth","key":"df05028229a2580b4189","wsHost":undefined,"wssHost":undefined,"wsPort":undefined,"wssPort":undefined,"cluster":"mt1","encrypted":false,"forceTLS":false,"disableStats":true,"enabledTransports":["ws"],"auth":{"headers":{"Authorization":"Bearer "}}}
  options.auth = options.auth || {}
  options.auth.headers = {
    ...options.auth.headers,
    ...getHeaders(ctx)
  }

  const echo = new Echo(options)

  ctx.$echo = echo
  inject('echo', echo)
}
