<template>
    <div class="l-page-contact">
        <div class="container">
            <div class="l-page-content">
                <div class="l-page-title">
                    <span class="--underlined-teal">Love</span> to hear from you,<br>
                    Get in touch! 👋🏻
                </div>

                <div class="card l-form-contact">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <FormInput class="mb-4" label="First Name" v-model="contact.firstname" :v="$v.contact.firstname" placeholder="E.G John"/>
                            </div>
                            <div class="col-md-4">
                                <FormInput class="mb-4" label="Last Name" v-model="contact.lastname" :v="$v.contact.lastname" placeholder="E.G Smith"/>
                            </div>
                            <div class="col-md-4">
                                <FormInput class="mb-4" label="Email Address" v-model="contact.email" :v="$v.contact.email" placeholder="Email address"/>
                            </div>
                        </div>
                        <FormInput class="mb-4" label="Topic" v-model="contact.subject" :v="$v.contact.subject" placeholder="ex. Topic name here"/>
                        <FormTextarea class="mb-4" label="Message" v-model="contact.message" :v="$v.contact.message" placeholder="Type Here..."/>
                        <FormCheckbox id="terms" v-model="contact.terms" :v="$v.contact.terms">
                            <template v-slot:labelslot>
                                I agree to <NuxtLink :to="{name: 'page.details', params: {slug: 'terms-and-conditions'}}">Terms & Conditions</NuxtLink>  &  <NuxtLink :to="{name: 'page.details', params: {slug: 'privacy-policy'}}">Privacy Policy</NuxtLink>
                            </template>
                        </FormCheckbox>
                        <Btn class="btn btn-primary --w280" :loading="loading.send" @click="sendContactMessage">Send</Btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import FormInput from "../components/FormInput.vue";
    import FormTextarea from "../components/FormTextarea.vue";
    import FormCheckbox from "../components/FormCheckbox.vue";
    import Btn from "../components/Btn.vue";
    import {sameAs} from "vuelidate/lib/validators";
    const { required, email } = require('vuelidate/lib/validators');

    export default {
        name: "ContactUs",
        components: {Btn, FormCheckbox, FormTextarea, FormInput},
        head: {
            title: 'Contact',
        },
        data: function () {
            return {
                loading: {
                    send: false
                },
                contact: {
                    customer_id: null,
                    firstname: '',
                    lastname: '',
                    email: '',
                    subject: '',
                    message: '',
                    terms: false,
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.user,
            }),
        },
        mounted() {
            if (this.user) {
                this.contact.customer_id = this.user.id;
                this.contact.firstname = this.user.firstname;
                this.contact.lastname = this.user.lastname;
                this.contact.email = this.user.email;
            }
        },
        validations: function() {
            let rules = {
                contact: {
                    firstname: {required},
                    lastname: {required},
                    email: {required, email},
                    subject: {required},
                    message: {required},
                    terms: {sameAs: sameAs(() => true)},
                },
            };
            return rules;
        },
        methods: {
            async sendContactMessage() {
                this.$v.contact.$touch();
                if (this.$v.contact.$invalid === true) {
                    return false;
                }
                let token = null;
                try {
                    token = await this.$recaptcha.execute('contact')
                } catch (error) {
                    console.log('Login error:', error)
                }
                this.loading.send = true;
                this.$axios.post("/cms/send-contact-message", {...this.contact, ...{token: token}})
                    .then( () => {
                        this.loading.send = false;
                        this.contact.firstname = '';
                        this.contact.lastname = '';
                        this.contact.email = '';
                        this.contact.subject = '';
                        this.contact.message = '';
                        this.contact.terms = false;
                        this.$v.contact.$reset();
                    })
                    .catch( (err)=> {
                        this.loading.send = false;
                        console.log(err)
                    })
            }
        }

    }
</script>
