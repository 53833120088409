<template>
    <a href="#" class="c-video-card" @click.prevent="showVideo">
        <div class="c-video-card__img-wrapper">
            <XImgset class="c-video-card__img img-fluid" :src="post.image" :width="357" :height="464" :alt="post.title"/>
            <div class="c-video-card__play-icon"><PlayIcon/></div>
        </div>
        <div class="c-video-card__content-wrapper">
            <div class="c-video-card__content">
                <div class="c-video-card__content-title">{{ post.name }}</div>
            </div>
        </div>
    </a>
</template>

<script>
import XImgset from "./XImgSet.vue";

export default {
    name: 'VideoCard',
    components: {XImgset},
    props: ['post'],
    data() {
        return {
            loading: {

            },
        }
    },
    methods: {
        postClick () {
            // this.$analytics.articleClick(this.post)
        },
        showVideo() {
            this.$emit('showVideo', this.post)
        },
    },
}
</script>
