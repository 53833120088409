export const metaMixin = {
    head() {
        return {
            title: this.meta.title ? this.meta.title + ' | BOLG' : (this.$route.meta ? this.$route.meta.title + ' | BOLG' : 'BOLG'),
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.meta.description,
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: this.meta.keywords,
                }
            ],
        };
    },
    data() {
        return {
            meta: {
                title: '',
                description: '',
                keywords: ''
            }
        };
    },
}

