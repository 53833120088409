<template>
    <div>
        <div class="container">
            <div class="l-page-content">
<!--                <div class="l-page-title">-->
<!--                    <span class="&#45;&#45;underlined">About Us</span>-->
<!--                </div>-->
                <div class="row mb-5 mb-2">
                    <div class="col-md-5 d-flex justify-content-center flex-column">
                        <div class="pe-md-5">
<!--                           <div class="badge badge-secondary-light mb-4">2021</div>-->
                           <div class="l-aboutus__title">About BOLG Biotechnology Open Lab Gate</div>
                           <div class="l-aboutus__content">
                               Welcome to BOLG Biotechnology Open Lab Gate, where innovation meets collaboration in the dynamic world of biotechnology.
                               We are more than just a platform; we are the heartbeat of the biotech community, fostering connections, driving advancements,
                               and shaping the future of the industry.
                           </div>
                           <div class="l-aboutus__title mt-2">Our Mission</div>
                           <div class="l-aboutus__content">
                               At BOLG Biotechnology Open Lab Gate, our mission is clear – to create a hub where professionals, enthusiasts, and companies
                               converge to share knowledge, exchange experiences, and propel the biotech industry to new heights.
                               We believe in the power of collaboration and the transformative potential that lies within the global biotech community.
                           </div>
                       </div>
                    </div>
                    <div class="col-md-7 mb-2">
                        <img src="/images/aboutus1.jpg" alt="" class="l-aboutus__img img-fluid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <img src="/images/aboutus2.jpg" alt="" class="l-aboutus__img img-fluid">
                    </div>
                    <div class="col-md-6 mb-2 d-flex justify-content-center flex-column">
                        <div class="pl-md-5">
<!--                            <div class="badge badge-secondary-light mb-4">2023</div>-->
                            <div class="l-aboutus__title">Connecting Minds, Advancing Biotech</div>
                            <div class="l-aboutus__content">
                                BOLG Biotechnology Open Lab Gate is more than a platform; it's a movement. We are passionate about connecting minds,
                                bridging gaps, and advancing the field of biotechnology. Whether you're an industry expert, a budding professional,
                                or a company seeking to make a mark, BOLG Biotechnology Open Lab Gate is your dedicated space to thrive.
                            </div>
                            <div class="l-aboutus__title mt-2">What Sets Us Apart</div>
                            <div class="l-aboutus__content">
                                Global Network: BOLG Biotechnology Open Lab Gate transcends geographical boundaries, creating a global network of biotech professionals, companies, and enthusiasts. Connect with like-minded individuals from around the world, sharing insights and expanding your horizons.
                                Innovation Hub: Our platform is designed to be an innovation hub, where ideas are born, collaborations flourish, and groundbreaking projects take shape. We believe in the power of collective intelligence to drive innovation in biotechnology.
                                Diverse Community: BOLG Biotechnology Open Lab Gate celebrates diversity. Whether you're a researcher, entrepreneur, student, or industry veteran, you have a place in our community. We value the richness that diverse perspectives bring to the table.
                            </div>
                        </div>
                    </div>

                </div>

                <div class="l-aboutus__title">What You Can Expect</div>
                <div class="l-aboutus__content">
                    <p>Cutting-Edge Resources: Stay ahead of the curve with access to cutting-edge resources, industry insights, and the latest trends in biotechnology.</p>
                    <p>Career Opportunities: Explore exciting career opportunities in the biotech realm. BOLG Biotechnology Open Lab Gate is your gateway to a world of possibilities, whether you're seeking employment or looking to hire top talent.</p>
                    <p>Company Showcase: For companies, BOLG Biotechnology Open Lab Gate provides a platform to showcase your innovations, services, and job opportunities. Enhance your visibility and connect with the right talent and collaborators.</p>
                </div>

                <div class="l-aboutus__title mt-2">Join Us in Shaping the Future
                </div>
                <div class="l-aboutus__content">
                    <p>Whether you're a seasoned professional, a student eager to learn, or a company with a vision, BOLG Biotechnology Open Lab Gate invites you to join us in shaping the future of biotechnology. Let's collaborate, innovate, and advance the field together. </p>
                    <p>Connect with us on BOLG Biotechnology Open Lab Gate and be part of a community that's driving the future of biotech. The journey begins here!</p>
                </div>

<!--                <div class="l-aboutus__section" v-if="brands.length">-->
<!--                    <div class="l-aboutus__title-info">Some of our partners</div>-->
<!--                    <div class="c-brands">-->
<!--                        <div class="c-brands__item" v-for="brand in brands">-->
<!--                            <img :src="brand.img" :alt="brand.name" />-->
<!--&lt;!&ndash;                            <span class="c-brands-slider__name">{{ brand.name}}</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="l-aboutus__section" v-if="team.length">
                    <div class="l-aboutus__title-info text-secondary">This is us!</div>
                    <div class="l-aboutus__title text-center">Meet our team</div>
                    <div class="l-aboutus__subtitle text-center" style="max-width: 780px">
                        Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do your best work.
                    </div>
                    <div class="row">
                        <div class="col-md-3" v-for="member in team">
                            <div class="c-team-member">
                                <div class="c-team-member__img">
                                    <XImgset class="img-fluid" :src="member.image" :width="254" :height="254" :alt="member.title"/>
                                </div>
                                <div class="c-team-member__name">{{ member.name }}</div>
                                <div class="c-team-member__function">{{ member.role }}</div>
                                <div class="c-team-member__desc">{{ member.description }}</div>
                                <div class="c-team-member__links" v-if="member.links.length">
                                    <a :href="member.links.linkedin target="_blank"" v-if="member.links.linkedin">
                                        <LinkedinIcon width="24" height="24"/>
                                    </a>
                                    <a :href="member.links.twitter" target="_blank" v-if="member.links.twitter">
                                        <TwitterIcon width="24" height="24"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-dark l-aboutus__section mb-5" v-if="false">
                    <div class="l-aboutus__title-info text-secondary">From our users</div>
                    <div class="l-aboutus__title text-center" style="font-weight: 500; max-width: 985px">A broad network of skilled people in many fields that is very hard to find elsewhere.</div>

                </div>

                <Newsletter/>
            </div>
        </div>
    </div>
</template>

<script>


import { metaMixin } from '~/mixins/metaMixin'

import Newsletter from "../partials/Newsletter.vue";
import XImgset from "../components/XImgSet.vue";

export default {
    name: 'AboutUs',
    components: {XImgset, Newsletter},
    mixins: [metaMixin],
    data () {
        return {
            loading: {
                data: false,
            },
            team: [],
            brands: [
                {name: 'Boltshift', img: '/images/partners/boltshift.svg'},
                {name: 'FeatherDev', img: '/images/partners/featherDev.svg'},
                {name: 'GlobalBank', img: '/images/partners/globalBank.svg'},
                {name: 'Lightbox', img: '/images/partners/lightbox.svg'},
                {name: 'Nietzhsche', img: '/images/partners/nietzhsche.svg'},
                {name: 'Spherule', img: '/images/partners/spherule.svg'},
            ]
        }
    },
    async fetch () {
        return this.getData()
    },
    mounted () {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    methods: {
        getData () {
            this.loading.data = true;
            return this.$axios.get('/cms/team-members')
                .then((res) => {
                    this.team = res.team;
                    // this.meta = res.data.meta
                })
                .catch((error) => {
                    console.error(error);
                    this.loading.data = false;
                });
        },
    }
}
</script>
