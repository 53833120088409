export const initMixin = {
    data() {
        return {
            inited: true,
            loadingInit: true
        };
    },
    async fetch() {
        // await this.getData();
    },
    async mounted () {
        if (this.$auth.getToken()) {
            await this.getUser();
        }else{
            this.inited = true;
            this.loadingInit = false;
            this.$store.commit('setLoaded', 'user');
            this.$auth.setRole('public')
            this.$acl.change('public')
            if (this.$route.meta.rule === 'isLogged' && this.$route.meta.rule !== 'isAny') {
                this.$router.push('/auth/login')
            }
        }
        this.makeActions();
    },
    methods: {
        getUser: function () {
            return this.$axios.get('/account/me')
              .then((res) =>{
                  this.$store.commit('setUser', res.user);
                  this.$store.commit('setProfiles', res.profiles);
                  this.$store.commit('setPlan', res.current_plan?.settings);
                  this.$store.commit('setLoaded', 'user');
                  this.$auth.setRole(res.role);
                  this.$acl.change('client')
                  this.inited = true
                  this.loadingInit = false

              })
              .catch(() =>{
                  this.$auth.setRole('public')
                  this.$acl.change('public')
                  this.$store.commit('setLoaded', 'user');
                  this.inited = true;
                  this.loadingInit = false
                  if (this.$route.meta.rule === 'isLogged' && this.$route.meta.rule !== 'isAny') {
                      this.$router.push('/auth/login')
                  }
              })
        },
        makeActions() {
            if (this.$route.query?.action) {
                let params = {};
                switch (this.$route.query.action) {
                    case 'employee_invitation':
                        params = JSON.parse(this.$route.query.params);
                        this.$axios.post('/account/employees/accept', {token: params.id})
                        break;
                    case 'member_invitation':
                        params = JSON.parse(this.$route.query.params);
                        this.$axios.post('/account/members/accept', {token: params.id})
                        break;
                    case 'email_confirmation':
                        params = JSON.parse(this.$route.query.params);
                        this.$axios.post('/auth/verify-email', {token: params.token})
                            .then(() => {
                                this.$router.push({name: 'auth.confirm'})
                            })
                        break;
                }
            }
        },
        getData: function () {
            return this.$axios.get('/get-data')
              .then((res) => {
                  this.$store.commit('setLoaded', 'serviceTypes');
              })
        },
        async getLocation () {
            await navigator.geolocation.getCurrentPosition(
              position => {
                  this.$store.commit('setPosition', {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                  })
              },
              error => {
                  console.log(error.message)
              },
            )
        },
        checkUser () {
            if (!this.user.id) {
                this.$router.push({ name: 'auth.login' })
            }
        },
    }
}

