<template>
    <div class="auth-content">
        <div class="row m-0 h-100">
            <!-- left section-login -->
            <div class="col-md-7 col-12 minh-100 px-0">
                <div class="p-2 minh-100 flexbox-container auth-col flex-column-sm">
                    <div class="text-center py-3" v-if="$mq == 'sm' || $mq == 'xs' || $mq == 'md'">
                        <nuxt-link to="/"><img class="img-fluid mb-3" src="/assets/logo.svg" :width="147" alt="BOLG"/></nuxt-link>
                    </div>
                    <div class="mx-auto auth-form " v-if="form === 'login'">
                        <div>
                            <h1 class="auth-title">Sign In</h1>
<!--                            <button @click="AuthProvider('paypal')" class="btn btn-outline-light mb-2 btn-block">-->
<!--                                <PayPalIcon style="margin-right: 4px"/>-->
<!--                                Continue With PayPal-->
<!--                            </button>-->
                            <button @click="AuthProvider('linkedin')" class="btn btn-outline-light mb-2 btn-block">
                                <LinkedInIcon style="margin-right: 4px"/>
                                Continue With Linkedin
                            </button>
                            <button @click="AuthProvider('google')" class="btn btn-outline-light mb-3 btn-block ml-0">
                                <GoogleIcon style="margin-right: 4px"/>
                                Continue With Google
                            </button>

                            <div class="word-hr mb-3"><span>Or</span></div>
                            <FormInput class="mb-2" label="Username" placeholder="Username" v-model="login.email" :v="$v.login.email" v-on:keyup.enter="signIn()"/>
                            <FormInputPassword class="mb-2" type="password" label="Password" placeholder="Password" v-model="login.password" :v="$v.login.password" v-on:keyup.enter="signIn()"/>
                            <div class="d-flex mb-4" :class="not_confirmed ? 'justify-content-between' : 'justify-content-end'">
                                <div :class="{'loading': loading.confirm}" v-if="not_confirmed"><a @click.prevent="resendConfirmationLink" class="card-link" href="#"><u>Resend confirmation email</u></a></div>
                            </div>
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <FormCheckbox id="keep_login" class="mb-2" v-model="login.keep_login">
                                    <template v-slot:labelslot>
                                        Keep me logged in
                                    </template>
                                </FormCheckbox>
                                <a @click.prevent="form = 'reset'" class="text-secondary mb-2" href="#">Forgot Password?</a>
                            </div>


                            <Btn class="btn-secondary mb-2 btn-block" :loading="loading.login" v-on:click="signIn()">Sign In</Btn>
                            <div class="text-center">Don't have an Account? <NuxtLink :to="{name: 'auth.user-type'}" class="text-secondary">Sign up</NuxtLink></div>
                        </div>
                    </div>
                    <div class="mx-auto auth-form " v-if="form === 'register'">
                        <div>
                            <h1 class="auth-title">Sign up</h1>

                            <button @click="AuthProvider('linkedin')" class="btn btn-outline-light mb-2 btn-block">
                                <LinkedInIcon style="margin-right: 4px"/>
                                Continue With Linkedin
                            </button>
                            <button @click="AuthProvider('google')" class="btn btn-outline-light mb-3 btn-block ml-0">
                                <GoogleIcon style="margin-right: 4px"/>
                                Continue With Google
                            </button>

                            <div class="word-hr mb-3"><span>Or</span></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <FormInput class="mb-2" label="First Name" placeholder="" v-model="register.firstname" :v="$v.register.firstname"/>
                                </div>
                                <div class="col-md-6">
                                    <FormInput class="mb-2" label="Last Name" placeholder="" v-model="register.lastname" :v="$v.register.lastname"/>
                                </div>
                            </div>
                            <FormInput type="email" name="email" @change="checkEmail"
                                       :class="{'loading-input': loading.check_email}"
                                       :error="check_email === true ? 'Email is used, please enter another one' : null"
                                       class="mb-2" label="Email address" placeholder="" v-model="register.email" :v="$v.register.email"/>
                            <FormInput class="mb-2" name="username" @change="checkUsername"
                                       :class="{'loading-input': loading.check_email}"
                                       :error="check_username === true ? 'Username is used, please enter another one' : null"
                                       label="Username" placeholder="Your BOLG username" v-model="register.username" :v="$v.register.username"/>
                            <FormInputPassword type="password" label="Password" placeholder="Minimum 8 characters" v-model="register.password" :v="$v.register.password"/>
                            <template v-if="register.user_type === 'freelancer'">
                                <FormInput class="mb-2" label="City" placeholder="" v-model="register.address.city_name"/>
                                <FormInput class="mb-2" label="Province" placeholder="" v-model="register.address.county_name"/>
                                <FormInput class="mb-2" label="ZIP code" placeholder="" v-model="register.address.postcode"/>
                            </template>
                            <FormGroup label="Country">
                                <CountrySelect v-model="register.address.country"/>
                            </FormGroup>
                            <FormInput type="phone" class="mb-2" label="Contact Phone" placeholder="Mobile Number" v-model="register.phone" :v="$v.register.phone">
                                <template v-slot:prepend>
                                    <input class="form-control-prepend" v-model="register.phone_prefix" :v="$v.register.phone_prefix">
                                </template>
                            </FormInput>

                            <FormCheckbox id="terms" v-model="register.terms" :v="$v.register.terms">
                                <template v-slot:labelslot>
                                    Yes, I understand and agree to BOLG’s <NuxtLink class="text-secondary" :to="{name: 'page.details', params: {slug: 'terms-and-conditions'}}">terms & conditions</NuxtLink> and <NuxtLink class="text-secondary" :to="{name: 'page.details', params: {slug: 'privacy-policy'}}">privacy policy</NuxtLink>.
                                </template>
                            </FormCheckbox>

                            <FormCheckbox id="18years" v-model="register.years18" :v="$v.register.years18">
                                <template v-slot:labelslot>
                                    I confirm that I am 18 years old or have reached the age of majority in my country, whichever is older.
                                </template>
                            </FormCheckbox>

                            <FormCheckbox id="marketing" v-model="register.marketing">
                                <template v-slot:labelslot v-if="register.user_type === 'client'">
                                    Yes, send me emails, tips, marketing information, and offers to find biotech freelancers I’m looking for.
                                </template>
                                <template v-slot:labelslot v-else-if="register.user_type === 'freelancer'">
                                    Yes, send me emails, tips, marketing information, and offers to find jobs I’m looking for
                                </template>
                                <template v-slot:labelslot v-else-if="register.user_type === 'company'">
                                    Yes, send us emails, tips, marketing information, and offers to advertise our products and services
                                </template>
                            </FormCheckbox>

                            <FormCheckbox id="newsletter" v-model="register.newsletter">
                                <template v-slot:labelslot>
                                    Yes, I agree to subscribe to BOLG’s newsletter.
                                </template>
                            </FormCheckbox>

                            <Btn class="btn-secondary mb-2 btn-block" :loading="loading.register" v-on:click="signUp()">Create Account</Btn>
                            <div class="text-center">Already Have an Account? <a href="#" class="text-secondary" @click.prevent="form = 'login'">Log in</a></div>
                        </div>
                    </div>
                    <div class="mx-auto auth-form " v-if="form === 'reset'">
                        <div>
                            <h1 class="auth-title">Forgot Password?</h1>

                            <FormInput class="mb-2" type="email" label="Email" placeholder="Email" v-model="reset.email" :v="$v.reset.email"/>

                            <Btn :loading="loading.reset" class="btn-secondary mb-2 btn-block" v-on:click="resetPassword()">Send</Btn>

                            <div class="text-center mb-2"><a @click.prevent="form = 'login'" href=""><small class="text-muted">Back</small></a></div>
                        </div>
                    </div>
                    <div class="mx-auto auth-form " v-if="form === 'recover'">
                        <div>
                            <h1 class="auth-title">Set New Password</h1>

                            <FormInput class="mb-2" label="Code" placeholder="" v-model="recover.code" :v="$v.recover.code"/>

                            <FormInputPassword class="mb-2" type="password" label="New password" placeholder="" v-model="recover.password" :v="$v.recover.password"/>
                            <FormInputPassword class="mb-2" type="password" label="Confirm new password" placeholder="" v-model="recover.confirm_password" :v="$v.recover.confirm_password"/>

                            <Btn :loading="loading.recover" :class="{'loading-btn': loading.recover}" class="btn-secondary mb-2 btn-block" v-on:click="recoverPassword()">Set new password</Btn>

                            <div class="text-center mb-2"><a @click.prevent="form = 'login'" href=""><small class="text-muted">Cancel</small></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right section image -->
            <div class="col-md-5 d-md-block d-none p-3 bg-authentication minh-100">
                <Bubbles class="l-auth__onboarding-bubbles"/>
                <div class="minh-100 flexbox-container flex-column auth-content__info mx-auto">
                    <nuxt-link to="/"><img class="img-fluid mb-3" src="/assets/logo-white.svg" :width="147" alt="BOLG"/></nuxt-link>
<!--                    <div class="auth-content__info-title">Over 2000+ companies <br> are offering a job</div>-->
<!--                    <div class="auth-content__info-examples">-->
<!--                        <AuthCompanyJobCard class="mb-2" :key="n" v-for="n in 5"/>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { required, sameAs, email, minLength, numeric } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'
import fadeOutEffect from '~/utils/fadeout'
import Btn from "~/components/Btn.vue";
import FormInput from "~/components/FormInput.vue";
import FormSelect from "~/components/FormSelect.vue";
import CountrySelect from "~/components/CountrySelect.vue";
import FormCheckbox from "~/components/FormCheckbox.vue";
import Bubbles from "~/assets/assets/bubbles.svg?inline";
import LinkedInIcon from "~/assets/icons/auth/linkedin.svg?inline";
import GoogleIcon from "~/assets/icons/auth/google.svg?inline";
import {initMixin} from "../../mixins/initMixin";
import AuthCompanyJobCard from "./AuthCompanyJobCard.vue";
import FormInputPassword from "@/components/FormInputPassword.vue";
import {metaMixin} from "@/mixins/metaMixin";


export default {
    name: 'Auth',
    mixins: [initMixin, metaMixin],
    components: {FormInputPassword, AuthCompanyJobCard, FormCheckbox, CountrySelect, FormSelect, FormInput, Btn, Bubbles, LinkedInIcon, GoogleIcon},
    data() {
        return {
            form: 'login',
            message: '',
            error: null,
            loading: {
                login: false,
                register: false,
                reset: false,
                recover: false,
                check_email: false,
                check_username: false,
            },
            login: {
                email: '',
                password: '',
                keep_login: false,
            },
            register: {
                firstname: '',
                lastname: '',
                username: '',
                email: '',
                phone_prefix: '+1',
                phone: '',
                password: '',
                terms: false,
                years18: false,
                marketing: false,
                newsletter: false,
                user_type: 'client',
                address: {
                    address: '',
                    city_name: '',
                    county_name: '',
                    postcode: '',
                    country: null,
                }
            },
            reset: {
                email: '',
            },
            recover: {
                email: '',
                code: '',
                password: '',
                confirm_password: '',
            },
            not_confirmed: false,
            check_email: null,
            check_username: null,
        }
    },
    validations: {
        login: {
            email: {required},
            password: {required},
        },
        register: {
            firstname: {required, minLength: minLength(2)},
            lastname: {required, minLength: minLength(2)},
            username: {required},
            email: {required, email},
            phone_prefix: {required},
            phone: {required, numeric},
            password: {required, minLength: minLength(8)},
            terms: {sameAs: sameAs( () => true ) },
            years18: {sameAs: sameAs( () => true ) },
        },
        reset: {
            email: {required, email}
        },
        recover: {
            password: {required},
            confirm_password: {required, sameAs: sameAs('password')},
        },
    },
    watch:{
        $route (to){
            if (to.name === 'register') {
                this.form = 'register';
                if (this.$route.params.type) {
                    this.register.user_type = this.$route.params.type;
                }
            } else if (to.name === 'login') {
                this.form = 'login';
            } else {
                this.form = to.name;
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    created: function () {
        if (this.$route.name === 'auth.login') {
            this.form = 'login';
        }else if (this.$route.name === 'auth.register') {
            this.form = 'register';
            if (this.$route.params.type) {
                this.register.user_type = this.$route.params.type;
            }
        }else if (this.$route.name === 'auth.reset') {
            this.form = 'reset';
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    mounted: function () {
        if (this.user.id) {
            this.$router.push({name: 'account'});
            return;
        } else {
            fadeOutEffect(document.getElementById('preloader'));
        }
        // if (this.$route.query.resetToken) {
        //     this.form = 'recover';
        //     this.recover.email = this.$route.query.email;
        //     this.recover.reset_token = this.$route.query.resetToken;
        // }
        if (this.$route.query.confirmToken) {
            this.confirmAccount();
        }
    },
    methods: {
        signIn: function () {
            this.$v.login.$touch();
            if (this.$v.login.$invalid == true) {
                return false;
            }
            this.loading.login = true;
            this.$axios.post("/auth/login", this.login)
                .then((res) => {
                    this.loading.login = false;
                    this.$store.commit('setUser', res.user);
                    this.$store.commit('setProfiles', res.profiles);
                    this.$store.commit('setPlan', res.current_plan?.settings);
                    this.$auth.setToken(res.token);
                    this.$auth.setRole(res.role);
                    this.$acl.change(res.user.type);
                    if (res.user.confirmed) {
                        this.$router.push({name: 'account.dashboard'});
                    }else{
                        this.$router.push({name: 'auth.confirm'});
                    }
                })
                .catch((error) => {
                    if (error.response.data.not_confirmed) {
                        this.not_confirmed = true;
                    }
                    this.loading.login = false;
                });
        },
        signUp: async function () {
            this.$v.register.$touch();
            if (this.$v.register.$invalid == true) {
                return false;
            }
            let token = null;
            try {
                token = await this.$recaptcha.execute('register')
            } catch (error) {
                console.log('Login error:', error)
            }
            this.error = '';
            this.message = '';
            this.loading.register = true;
            this.$axios.post("/auth/register", {...this.register, ...{token: token}})
                .then((res) => {
                    this.loading.register = false;
                    this.$store.commit('setUser', res.user);
                    this.$store.commit('setPlan', res.current_plan?.settings);
                    this.$auth.setToken(res.token);
                    this.$auth.setRole(res.role);
                    this.$acl.change(res.user.type);
                    if (res.user.confirmed) {
                        this.$router.push({name: 'auth.onboarding'});
                    }else{
                        this.$router.push({name: 'auth.confirm'});
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.register = false;
                });
        },
        resetPassword: function () {
            this.$v.reset.$touch();
            if (this.$v.reset.$invalid == true) {
                return false;
            }
            this.loading.reset = true;
            this.$axios.post("/auth/recover", this.reset)
                .then((res) =>{
                    this.form = 'recover';
                    this.recover.email = this.reset.email
                    this.loading.reset = false;
                })
                .catch((res) =>{
                    this.loading.reset = false;
                })
        },
        recoverPassword: function () {
            this.$v.recover.$touch();
            if (this.$v.recover.$invalid == true) {
                return false;
            }
            this.loading.recover = true;
            this.$axios.post("/auth/reset", this.recover)
                .then((res) =>{
                    this.loading.recover = false;
                    this.form = "login";
                    this.$router.replace(this.$router.currentRoute.path);
                })
                .catch((res) =>{
                    this.loading.recover = false;
                })
        },
        AuthProvider(provider) {
            this.$socialAuth.authenticate(provider).then(response =>{
                this.SocialLogin(provider,response)
            }).catch(err => {
                console.log({err:err})
            })
        },
        SocialLogin(provider, response){
            this.$axios.post(`/auth/login/${provider}/callback`, {...response, ...{user_type: this.register.user_type, action: this.form}})
                .then(res => {
                    console.log({res})
                    if (res.token) {
                        this.$store.commit('setUser', res.user);
                        this.$store.commit('setProfiles', res.profiles);
                        this.$store.commit('setPlan', res.current_plan?.settings);
                        this.$auth.setToken(res.token);
                        this.$auth.setRole(res.role);
                        this.$acl.change(res.user.type);
                        if (res.user.confirmed) {
                            if (res.new_account) {
                                this.$router.push({name: 'auth.onboarding'});
                            }else{
                                this.$router.push({name: 'account.dashboard'});
                            }
                        }else{
                            this.$router.push({name: 'auth.confirm'});
                        }
                    }
                }).catch(err => {
                console.log({err:err})
            })
        },
        confirmAccount () {
            this.loading.confirm = true;
            this.$axios.post("/auth/confirm-account", {
                confirmation_token: this.$route.query['confirmToken']
            })
                .then((res) =>{
                    this.loading.confirm = false;
                    this.$router.replace(this.$router.currentRoute.path);
                })
                .catch((res) =>{
                    this.loading.confirm = false;
                })
        },
        resendConfirmationLink() {
            this.loading.confirm = true;
            this.$axios.post("/auth/resend-confirmation-link", {
                email: this.login.email,
            })
                .then((res) =>{
                    this.loading.confirm = false;
                })
                .catch((res) =>{
                    this.loading.confirm = false;
                })
        },
        checkEmail() {
            this.$v.register.email.$touch();
            if (this.$v.register.email.$invalid == true) {
                return false;
            }
            this.loading.check_email = true;
            this.$axios.post("/auth/check-email", {
                email: this.register.email,
            })
                .then((res) =>{
                    this.check_email = res.exists;
                    this.loading.check_email = false;
                })
                .catch((res) =>{
                    this.loading.check_email = false;
                })
        },
        checkUsername() {
            this.$v.register.username.$touch();
            if (this.$v.register.username.$invalid == true) {
                return false;
            }
            this.loading.check_username = true;
            this.$axios.post("/auth/check-username", {
                username: this.register.username,
            })
                .then((res) =>{
                    this.check_username = res.exists;
                    this.loading.check_username = false;
                })
                .catch((res) =>{
                    this.loading.check_username = false;
                })
        }
    },
}
</script>
